import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";

import api from "../../services/Api";

import AuctionCard from "./AuctionCard";

import banner from "../../assets/images/banners/banneri-1920x400.png";
import banner2 from "../../assets/images/banners/banneri-1920x400_2.png";

const AuctionList = ({ filters, limit, banners = false }) => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [randomBanner, setRandomBanner] = useState(null);

    useEffect(() => {
        const banners = [
            {'image': banner, 'url': 'https://www.sunholidays.fi/'},
            {'image': banner2, 'url': 'https://www.vaihtolomat.fi/'},
        ];
    
        setRandomBanner(banners[Math.floor(Math.random() * banners.length)]);
    }, []);

    useEffect(() => {
        fetchAuctions(filters, limit);
    }, [filters, limit]);

    const fetchAuctions = async (filters, limit) => {
        setLoading(true);

        try {
            const response = await api.getAuctions(filters, limit);
            setAuctions(response);
        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mb-10 relative">
            {!loading & (auctions.length === 0) ? (
                <div className="text-2xl text-gray-800 mt-5">Valitettavasti haku ei löytänyt yhtään kohdetta.</div>
            ) : (
                <div className="relative">
                    {loading && (
                        <div className="flex justify-center items-center absolute w-full bg-white bg-opacity-25 h-full z-20">
                            <Spin size="large" />
                        </div>
                    )}
                    {!limit && (
                        <div className="text-2xl text-gray-800 mb-2 mt-5">
                            Löytyi {auctions.length} kohde{auctions.length > 1 && "tta"}
                        </div>
                    )}
                    <div className="md:grid md:grid-cols-4 md:gap-5">
                        {auctions?.map((auction, index) => (
                            <>
                                <AuctionCard key={auction.number} auction={auction} />
                                {banners && (index === 3 || (index > 3 && (index - 3) % 8 === 0)) && (
                                    <div className="hidden md:block md:col-span-4">
                                        <Link to={randomBanner.url} target="_blank">
                                            <img src={randomBanner.image} alt="Banner" className="w-full object-cover rounded" />
                                        </Link>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AuctionList;
