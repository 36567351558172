import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";

const ContractPersonForm = ({ fields, add, remove, label, initialData, limit }) => {
    // State to track if the checkbox is checked (initially false)
    const [isMandateChecked, setIsMandateChecked] = useState(false);

    // Check if the mandateName or mandatePhonenumber fields are filled (for fetched contract)
    useEffect(() => {
        fields.forEach((field) => {
            const mandateName = field.mandateName;
            const mandatePhonenumber = field.mandatePhonenumber;

            if (mandateName || mandatePhonenumber) {
                setIsMandateChecked(true); // Pre-check the checkbox if mandate details exist
            }
        });
    }, [fields]);

    const handleCheckboxChange = (e) => {
        setIsMandateChecked(e.target.checked);
    };

    return (
        <>
            {fields.map((field, index) => (
                <div key={`${field.key}-${index}`}>
                    <div className="border-b border-gray-400 mb-1 hidden"></div>
                    {limit && (
                        <div className="grid grid-cols-5 gap-x-2">
                            <Form.Item
                                label="Yrityksen nimi"
                                name={[field.name, "companyName"]}
                                rules={[{ required: true, message: "Yrityksen nimi on pakollinen" }]}
                            >
                                <Input placeholder="Yrityksen nimi" />
                            </Form.Item>
                            <Form.Item
                                label="Y-tunnus"
                                name={[field.name, "businessId"]}
                                rules={[{ required: true, message: "Y-tunnus" }]}
                            >
                                <Input placeholder="Y-tunnus" />
                            </Form.Item>
                        </div>
                    )}
                    <div className="font-bold mb-2">{limit ? "Yhteyshenkilö" : label + " " + (index + 1)}</div>
                    <div className="grid grid-cols-5 gap-x-2">
                        <Form.Item
                            label="Etunimi"
                            name={[field.name, "firstname"]}
                            rules={[{ required: true, message: "Etunimi on pakollinen" }]}
                        >
                            <Input placeholder="Etunimi" />
                        </Form.Item>
                        <Form.Item
                            label="Sukunimi"
                            name={[field.name, "lastname"]}
                            rules={[{ required: true, message: "Sukunimi on pakollinen" }]}
                        >
                            <Input placeholder="Sukunimi" />
                        </Form.Item>
                        <Form.Item
                            label="Sähköposti"
                            name={[field.name, "email"]}
                            rules={[{ required: true, message: "Sähköposti on pakollinen" }]}
                        >
                            <Input placeholder="Sähköposti" />
                        </Form.Item>
                        <Form.Item
                            label="Puhelinnumero"
                            name={[field.name, "phonenumber"]}
                            rules={[{ required: true, message: "Puhelinnumero on pakollinen" }]}
                        >
                            <Input placeholder="Puhelinnumero" />
                        </Form.Item>
                        <Form.Item
                            label="Osoite"
                            name={[field.name, "address"]}
                            rules={[{ required: true, message: "Osoite on pakollinen" }]}
                        >
                            <Input placeholder="Osoite" />
                        </Form.Item>
                        <Form.Item
                            label="Postinumero"
                            name={[field.name, "zipcode"]}
                            rules={[{ required: true, message: "Postinumero on pakollinen" }]}
                        >
                            <Input placeholder="Postinumero" />
                        </Form.Item>
                        <Form.Item
                            label="Kaupunki"
                            name={[field.name, "city"]}
                            rules={[{ required: true, message: "Kaupunki on pakollinen" }]}
                        >
                            <Input placeholder="Kaupunki" />
                        </Form.Item>
                        <Form.Item
                            label="Maa"
                            name={[field.name, "country"]}
                            rules={[{ required: true, message: "Maa on pakollinen" }]}
                        >
                            <Input placeholder="Maa" />
                        </Form.Item>
                        {!limit && (
                            <Form.Item
                                label="Henkilötunnus"
                                name={[field.name, "ssn"]}
                                rules={[{ required: true, message: "Henkilötunnus on pakollinen" }]}
                            >
                                <Input placeholder="Henkilötunnus" />
                            </Form.Item>
                        )}
                        {fields.length > 1 && (
                            <div className="flex justify-end">
                                <MinusCircleOutlined
                                    className="text-red-700 text-lg"
                                    onClick={() => remove(field.name)}
                                />
                            </div>
                        )}
                    </div>

                    {/* Checkbox for mandate details */}
                    <div className="grid grid-cols-3 gap-x-2">
                        <Form.Item>
                            <Checkbox
                                checked={isMandateChecked}
                                onChange={handleCheckboxChange}
                                className="select-none flex items-center"
                            >
                                Allekirjoitetaan valtakirjalla
                                
                                <Tooltip title="Muista toimittaa allekirjoitettu valtakirja toiselle osapuolelle.">
                                    <div className="text-lg inline">
                                        <QuestionCircleFilled className="text-blue-700 ml-2" />
                                    </div>
                                </Tooltip>
                            </Checkbox>
                        </Form.Item>

                        {/* Show mandate fields if the checkbox is checked */}
                        {isMandateChecked && (
                            <>
                                <Form.Item
                                    label="Valtuutetun henkilötunnus"
                                    name={[field.name, "mandateName"]}
                                    rules={[{ required: isMandateChecked, message: "Valtuutetun henkilötunnus on pakollinen" }]}
                                >
                                    <Input placeholder="Valtuutetun henkilötunnus" />
                                </Form.Item>
                                <Form.Item
                                    label="Valtuutetun puhelinnumero"
                                    name={[field.name, "mandatePhonenumber"]}
                                    rules={[
                                        {
                                            required: isMandateChecked,
                                            message: "Valtuutetun puhelinnumero on pakollinen",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Valtuutetun puhelinnumero" />
                                </Form.Item>
                            </>
                        )}
                    </div>
                </div>
            ))}
            {!limit && (
                <Form.Item>
                    <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                        Lisää {label.toLowerCase()}
                    </Button>
                </Form.Item>
            )}
        </>
    );
};

export default ContractPersonForm;
