import React, { useEffect, useState } from "react";
import api from "../../services/Api"; // Import your API service
import { Spin } from "antd";

const AuctionCountdownTimer = ({ auction }) => {
    const [timeLeft, setTimeLeft] = useState("");
    const [effectClass, setEffectClass] = useState("");
    const [danger, setDanger] = useState(false);

    useEffect(() => {
        if (auction && auction.ends) {
            const interval = setInterval(() => {
                const now = new Date();
                const endDate = new Date(auction.ends.date);
                const timeDiff = endDate - now;

                if (timeDiff <= 0) {
                    clearInterval(interval);
                    setTimeLeft("Päättynyt");

                    // Update the auction status to expired
                    if (auction.status !== "expired") {
                        // Set the auction status to expired locally
                        auction.status = "expired";

                        // Call API to set the auction as expired
                        setAuctionExpired(auction.number);
                    }
                } else {
                    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                    if (days > 0) {
                        setTimeLeft(`${days} pv ${hours} t`);
                    } else if (hours > 0) {
                        setTimeLeft(`${hours} t ${minutes} min`);
                    } else {
                        setTimeLeft(`${minutes} min ${seconds} s`);
                    }

                    if (days > 0 || hours > 0) {
                        setEffectClass("");
                    } else {
                        setEffectClass("text-red-700");
                    }

                    if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
                        setDanger(true);
                    }
                    else {
                        setDanger(false);
                    }
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [auction]);

    const setAuctionExpired = async (auctionUuid) => {
        try {
            await api.setAuctionExpired(auction.uuid);
        } catch (error) {
            console.error(`Failed to set auction ${auctionUuid} as expired:`, error);
        }
    };

    return (
        <div className="relative">
            <div className={effectClass}>{timeLeft ? timeLeft : <Spin />}</div>
            {danger && <div className="top-0 animate-ping absolute text-red-700">{timeLeft ? timeLeft : <Spin />}</div>}
        </div>
    );
};

export default AuctionCountdownTimer;