import { Spin } from "antd";
import React, { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom";

import api from "../../services/Api";

const AuthReturn = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const authorizationCode = params.get("code");
        const state = params.get("state");

        if (authorizationCode && state) {
            const exchangeCodeForToken = async () => {
                try {
                    const response = await api.exchangeAuthCodeForToken({ code: authorizationCode, state });
                    
                } catch (err) {
                    setError("Authentication failed. Please try again.");
                }
            };

            exchangeCodeForToken();
        } else {
            setError("Invalid response from authentication service.");
        }
    }, []);

    return (
        <div className="m-10 flex items-center text-base">
            <Spin className="mr-5" /> Viimeistellään tunnistusta...
        </div>
    )
}

export default AuthReturn