import React, { useState, useEffect, useContext } from "react";
import { Form, Button, message, Spin, InputNumber, Input, Select, Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import api from "../../services/Api";
import CKEditorWrapper from "../../components/common/CKEditorWrapper";
import { AuthContext } from "../../context/AuthContext";

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { user, hasRole } = useContext(AuthContext);
    const [editorVisible, setEditorVisible] = useState({});

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await api.getSettings();
            setSettings(response);
        } catch (error) {
            message.error("Virhe haettaessa asetuksia");
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            await api.saveSettings(settings);
            message.success("Asennukset tallennettu");
        } catch (error) {
            message.error("Virhe tallennettaessa asetuksia");
        } finally {
            setSaving(false);
        }
    };

    // Function to handle input change for any setting property
    const handleInputChange = (key, property, value) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting) => (setting.id === key ? { ...setting, [property]: value } : setting))
        );
    };

    // Function to remove a setting
    const handleRemoveSetting = async (key) => {
        try {
            await api.deleteSetting(key);
            setSettings((prevSettings) => prevSettings.filter((setting) => setting.id !== key));
            message.success("Asetus poistettu");
        } catch (error) {
            message.error("Virhe poistettaessa asetusta");
        }
    };

    const toggleEditorVisibility = (key) => {
        setEditorVisible((prevEditorVisible) => ({
            ...prevEditorVisible,
            [key]: !prevEditorVisible[key],
        }));
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Asetukset</h1>
            {loading ? (
                <Spin />
            ) : (
                <Form layout="horizontal" onFinish={handleSave}>
                    {settings?.map((setting, index) => (
                        <Form.Item key={index}>
                            <div className="font-bold">{setting.label}</div>
                            {hasRole(user.roles, "ROLE_GOD") && (
                                <div className="flex gap-5 mb-2">
                                    <Input
                                        value={setting.label}
                                        onChange={(e) => handleInputChange(setting.id, "label", e.target.value)}
                                    />
                                    <Input
                                        value={setting.name}
                                        onChange={(e) => handleInputChange(setting.id, "name", e.target.value)}
                                    />
                                    <Select
                                        value={setting.type}
                                        onChange={(value) => handleInputChange(setting.id, "type", value)}
                                    >
                                        <Select.Option value="text">Text</Select.Option>
                                        <Select.Option value="number">Number</Select.Option>
                                        <Select.Option value="string">String</Select.Option>
                                    </Select>
                                    <Popconfirm
                                        title="Are you sure to delete this setting?"
                                        onConfirm={() => handleRemoveSetting(setting.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteFilled className="text-red-600" />
                                    </Popconfirm>
                                </div>
                            )}
                            {setting.type === "text" && (
                                <>
                                    <div
                                        className="text-primary cursor-pointer select-none"
                                        onClick={() => toggleEditorVisibility(setting.id)}
                                    >
                                        Näytä / piilota
                                    </div>
                                    {editorVisible[setting.id] && (
                                        <CKEditorWrapper
                                            content={setting.value}
                                            setContent={(content) => handleInputChange(setting.id, "value", content)}
                                        />
                                    )}
                                </>
                            )}
                            {setting.type === "number" && (
                                <InputNumber
                                    type="number"
                                    value={setting.value}
                                    onChange={(value) => handleInputChange(setting.id, "value", value)}
                                />
                            )}
                            {setting.type === "string" && (
                                <Input
                                    value={setting.value}
                                    onChange={(e) => handleInputChange(setting.id, "value", e.target.value)}
                                />
                            )}
                        </Form.Item>
                    ))}

                    {hasRole(user.roles, "ROLE_GOD") && (
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() =>
                                    setSettings((prevSettings) => [
                                        ...prevSettings,
                                        {
                                            id: `new_setting_${settings.length + 1}`, // Unique id for new setting
                                            name: "new_setting",
                                            label: "New Setting",
                                            type: "text",
                                            value: "",
                                        },
                                    ])
                                }
                            >
                                Lisää asetus
                            </Button>
                        </Form.Item>
                    )}

                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit" loading={saving}>
                            Tallenna
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default Settings;
