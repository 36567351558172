import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import api from "../../services/Api";
import PropertyForm from "../../components/forms/PropertyForm";

const PropertyEdit = () => {
    const { id = 0 } = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [galleryModalOpen, setGalleryModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const response = await api.getUserProperty(id);
                setProperty(response);
                setSelectedImages(response.images || []);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching property:", error);
                message.error("Failed to load property.");
                setLoading(false);
            }
        };

        if (id) {
            fetchProperty();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleSaveProperty = async (values) => {
        setSaving(true);
    
        try {
            const updatedProperty = {
                ...values,
                images: selectedImages,
            };

            const response = await api.saveUserProperty(id, updatedProperty);
            message.success("Kohde tallennettiin onnistuneesti");

            if (response.new === true) {
                navigate(`/hallinta/kohde/muokkaa/${response.id}`);
            }
        } catch (error) {
            console.error("Error saving property:", error);
            message.error("Error saving property");
        } finally {
            setSaving(false);
        }
    };

    const handleImageSelection = (images) => {
        setSelectedImages(images);
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div className="property-edit">
            <h1>{id ? "Muokkaa kohteen tietoja" : "Lisää uusi kohde"}</h1>
            
            <PropertyForm
                initialValues={property}
                onFinish={handleSaveProperty}
                loading={saving}
                galleryModalOpen={galleryModalOpen}
                setGalleryModalOpen={setGalleryModalOpen}
                selectedImages={selectedImages}
                handleImageSelection={handleImageSelection}
            />
        </div>
    );
};

export default PropertyEdit;
