import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Breadcrumbs from "../common/Breadcrumbs";
import Sidebar from "../common/Sidebar";

const AdminLayout = ({ children }) => {
    return (
        <div className="flex flex-col h-dvh">
            <Header pageType={1} />
            <div className="px-5 2xl:px-0 flex-grow">
                <Breadcrumbs />
                <main>
                    <div className="container m-auto max-w-7xl flex">
                        <div className="w-1/6 mr-5">
                            <Sidebar />
                        </div>
                        <div className="w-5/6">{children}</div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default AdminLayout;
