import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuOutlined, LoginOutlined, LogoutOutlined, UserOutlined, HeartOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { Badge, Drawer, Button } from "antd";

import logoWhite from "../../assets/images/lomahuuto-fi-logo-white-1024.png";
import logoDark from "../../assets/images/lomahuuto-fi-logo-1024.png";
import LoginModal from "../modals/LoginModal";
import FavoritesDrawer from "./FavoritesDrawer";

import api from "../../services/Api";

const Header = ({ pageType }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const { showLoginModal, isLoginModalOpen, hideLoginModal } = useContext(ModalContext);
    const [sticky, setSticky] = useState(false);
    const [favoritesDrawerOpen, setFavoritesDrawerOpen] = useState(false);
    const [badges, setBadges] = useState({
        auctions: 0,
        favorites: 0,
    });
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    const fetchBadges = async () => {
        if (!isAuthenticated) {
            return;
        }

        try {
            const response = await api.getBadges();
            setBadges(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchBadges();
            const intervalId = setInterval(fetchBadges, 10000);
            return () => clearInterval(intervalId);
        }
    }, [isAuthenticated]);

    const showFavoritesDrawer = () => {
        setFavoritesDrawerOpen(true);
    };

    const onClose = () => {
        setFavoritesDrawerOpen(false);
    };

    const stickyClass = [
        "sticky",
        "top-0",
        "bg-primary",
        "z-50",
        "text-white",
        "p-2",
        "md:py-3",
        "shadow-md",
        "shadow-black/30",
    ];

    const headerClass = [
        "text-black",
        "transition-all",
        "duration-300",
        "ease-in-out",
        "p-5",
        "md:py-5",
        pageType === 0 ? "" : "bg-primary",
    ];

    // Function to apply dynamic classes based on sticky or pageType
    const linkClass = (isSticky) =>
        isSticky || pageType === 1
            ? "flex cursor-pointer text-white hover:text-white text-base hover:border-b-2 hover:border-b-white hover:pb-1 transition-all duration-100 ease-in-out"
            : "flex cursor-pointer text-black hover:text-black text-base hover:border-b-2 hover:border-b-black hover:pb-1 transition-all duration-100 ease-in-out";

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 150) {
                setSticky(true);
            }

            if (window.scrollY < 100) {
                setSticky(false);
            }
        });
    });

    const openMobileDrawer = () => {
        setMobileDrawerOpen(true);
    };

    const closeMobileDrawer = () => {
        setMobileDrawerOpen(false);
    };

    return (
        <>
            <header
                className={sticky ? `${headerClass.join(" ")} ${stickyClass.join(" ")}` : `${headerClass.join(" ")}`}
            >
                <div
                    className={
                        "container m-auto max-w-screen-2xl flex flex-wrap items-center justify-between px-4 md:px-0 text-base"
                    }
                >
                    <Link to="/">
                        <img
                            src={pageType === 1 || sticky ? logoWhite : logoDark}
                            alt="Lomahuuto.fi"
                            className="w-60"
                            width={sticky ? 200 : 300}
                        />
                    </Link>

                    {/* Desktop Menu (md and larger) */}
                    <div className="hidden md:flex items-center space-x-4 h-10">
                        <Link to="/asiakaspalvelu" className={linkClass(sticky)}>
                            Asiakaspalvelu
                        </Link>
                        <Link to="/yhteystiedot" className={linkClass(sticky)}>
                            Yhteystiedot
                        </Link>
                        <span className={linkClass(sticky)}>|</span>

                        {isAuthenticated ? (
                            <>
                                <Badge count={badges.auctions} size="small" color="blue" offset={[4, 0]}>
                                    <Link className={linkClass(sticky)} to="/oma-sivu">
                                        <UserOutlined className="mr-1" />
                                        Oma tili
                                    </Link>
                                </Badge>
                                <Badge count={badges.favorites} size="small" color="blue" offset={[4, 0]}>
                                    <div className={linkClass(sticky)} onClick={showFavoritesDrawer}>
                                        <HeartOutlined className="mr-1" />
                                        Suosikit
                                    </div>
                                </Badge>
                                <Link className={linkClass(sticky)} onClick={logout}>
                                    <LogoutOutlined className="mr-1" />
                                    Kirjaudu ulos
                                </Link>
                                <FavoritesDrawer open={favoritesDrawerOpen} onClose={onClose} />
                            </>
                        ) : (
                            <div onClick={() => showLoginModal("login")} className={linkClass(sticky)}>
                                <LoginOutlined className="mr-2 text-lg md:text-base" />
                                <span className="hidden md:block">Kirjaudu</span>
                            </div>
                        )}
                    </div>

                    {/* Mobile Menu Button (below md) */}
                    <div className={sticky || pageType === 1 ? "md:hidden text-white" : "md:hidden text-black"}>
                        <MenuOutlined onClick={openMobileDrawer} />
                    </div>

                    {/* Mobile Drawer (below md) */}
                    <Drawer
                        title="Valikko"
                        placement="right"
                        closable={true}
                        onClose={closeMobileDrawer}
                        open={mobileDrawerOpen}
                    >
                        <div className="flex flex-col space-y-4">
                            <Link to="/asiakaspalvelu" onClick={closeMobileDrawer}>
                                Asiakaspalvelu
                            </Link>
                            <Link to="/yhteystiedot" onClick={closeMobileDrawer}>
                                Yhteystiedot
                            </Link>
                            <hr />
                            {isAuthenticated ? (
                                <>
                                    <Badge count={badges.auctions} size="small" color="blue" offset={[4, 0]}>
                                        <Link to="/oma-sivu" onClick={closeMobileDrawer}>
                                            <UserOutlined className="mr-2" />
                                            Oma tili
                                        </Link>
                                    </Badge>
                                    <Badge count={badges.favorites} size="small" color="blue" offset={[4, 0]}>
                                        <div onClick={showFavoritesDrawer} className="cursor-pointer">
                                            <HeartOutlined className="mr-2" />
                                            Suosikit
                                        </div>
                                    </Badge>
                                    <Link onClick={logout}>
                                        <LogoutOutlined className="mr-2" />
                                        Kirjaudu ulos
                                    </Link>
                                    <FavoritesDrawer open={favoritesDrawerOpen} onClose={onClose} />
                                </>
                            ) : (
                                <div onClick={() => showLoginModal("login")}>
                                    <LoginOutlined className="mr-2 text-lg md:text-base" />
                                    Kirjaudu
                                </div>
                            )}
                        </div>
                    </Drawer>
                </div>
                <LoginModal isOpen={isLoginModalOpen} onClose={hideLoginModal} />
            </header>
        </>
    );
};

export default Header;
