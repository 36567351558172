import React, { useEffect, useState, useCallback } from "react";
import AuctionList from "../components/common/AuctionList";
import AuctionFilters from "../components/common/AuctionFilters";
import { useSearchParams } from "react-router-dom";
import api from "../services/Api";

const AuctionListPage = () => {
    const [searchParams] = useSearchParams();

    const types = {
        myytavat: "sale",
        vuokrattavat: "rent",
        huutokaupat: "auction",
    };

    const [filters, setFilters] = useState({
        types: searchParams.get("t") ? searchParams.get("t").split(",") : [],
        areas: searchParams.get("a") ? searchParams.get("a").split(",") : [],
        propertyWeeks: [],
        beds: null,
        priceMax: null,
        attributes: [],
    });

    const [areas, setAreas] = useState([]);
    const [loadingAreas, setLoadingAreas] = useState(false);
    const [areasError, setAreasError] = useState(null);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    useEffect(() => {
        fetchAreas();
    }, []);

    const fetchAreas = async () => {
        setLoadingAreas(true);
        try {
            const response = await api.getAvailableAreasAuctions();
            setAreas(response);
        } catch (error) {
            console.error("Error fetching areas:", error);
            setAreasError("Failed to load areas.");
        } finally {
            setLoadingAreas(false);
        }
    };

    return (
        <div className="bg-gray-50 w-full">
            <section className="container max-w-screen-2xl m-auto pt-10">
                <h1 className="text-2xl md:text-3xl mb-5 font-medium uppercase text-primary">Kohdehaku</h1>
                <AuctionFilters
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    areas={areas}
                    loadingAreas={loadingAreas}
                    areasError={areasError}
                />
                <AuctionList filters={filters} banners={true} />
            </section>
        </div>
    );
};

export default AuctionListPage;
