import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, ExportOutlined } from "@ant-design/icons";

import vaihtolomatLogo from "../../../assets/images/vaihtolomat.fi-logo-500.png";

const URL =
    process.env.NODE_ENV === "development" ? "http://api-lomahuuto.localhost" : "https://api-lomahuuto.mkoskinen.net";

const AuctionDetails = ({ auction }) => {
    const ownershipStrings = {
        "1/4": "joka neljäs viikko, katso viikkoliite",
        "1/6": "joka kuudes viikko, katso viikkoliite",
        "1/8": "joka kahdeksas viikko, katso viikkoliite",
        "1/10": "joka kymmenes viikko, katso viikkoliite",
    };

    return (
        <div className="relative">
            <Link
                to="https://vaihtolomat.fi"
                target="_blank"
                className="hover:scale-103 transition-all transition-duration-200  hover:text-black flex flex-col items-center text-center px-4 py-2 rounded bg-gradient-to-r from-blue-200 to-cyan-200 absolute right-0 border border-blue-300"
            >
                <div>Tarkista kohteen lomakrediittiarvo!</div>
                <img src={vaihtolomatLogo} alt="Vaihtolomat.fi" className="w-60 mt-2" />
            </Link>

            <h3>Kohteen tiedot</h3>
            {auction.property?.address && (
                <div className="mt-5">
                    <div>
                        Osoite: {auction.property?.address}, {auction.property?.zipcode} {auction.property?.city}
                    </div>
                    {auction.roomNumber && <div>Huoneiston tunnus: {auction.roomNumber}</div>}
                    {auction.apartmentDescription && <div>Huoneistokuvaus: {auction.apartmentDescription}</div>}
                    {auction.propertyAttributes?.area && (
                        <div>Huoneiston koko: {auction.propertyAttributes.area} m²</div>
                    )}
                    {auction.propertyAttributes?.beds && (
                        <div>
                            Nukkumapaikkoja: {auction.propertyAttributes.beds}
                            {auction.propertyAttributes.extrabeds && <> + {auction.propertyAttributes.extrabeds}</>}
                        </div>
                    )}
                    <div>
                        Omistusmuoto:{" "}
                        {auction.property.ownershipType === "week"
                            ? "viikko-osake"
                            : auction.property.ownershipType + " osaomistus"}
                    </div>
                    {auction.maintenanceCharge && auction.auctionType !== "rent" && (
                        <>
                            <div>Viimeisin vahvistettu vastikkeen määrä: {auction.maintenanceCharge} &euro;</div>
                            <div>
                                Vastikkeen maksuväli: {auction.property.ownershipType === "week" ? "vuosi" : "kuukausi"}
                            </div>
                        </>
                    )}
                </div>
            )}

            {auction.property?.serviceAddress && (
                <div className="mt-5">
                    <strong>Avainpalvelun osoite</strong>
                    <br />
                    {auction.property?.serviceAddress}, {auction.property?.serviceZipcode}{" "}
                    {auction.property?.serviceCity}
                </div>
            )}

            {auction.property?.website && auction.property.website !== 'nan' && (
                <div className="mt-5">
                    <div>
                        <strong>Linkki kohteen sivuille</strong>
                    </div>
                    <Link target="_blank" to={auction.property?.website} className="text-blue-600">
                        <ExportOutlined className="mr-2" />
                        {auction.property?.website}
                    </Link>
                </div>
            )}

            {auction.propertyWeek && (
                <div className="mt-5">
                    <h3 className="mb-0">
                        {auction.type !== "rent" ? (
                            <>Seuraava huoneiston käyttöoikeus</>
                        ) : (
                            <>Huoneisto vuokrataan ajalle</>
                        )}
                    </h3>
                    <div>
                        {auction.property.ownershipType === "week" ? (
                            <>
                                Viikko {auction.propertyWeek} / {auction.propertyWeekDates}
                            </>
                        ) : (
                            <div className="first-letter:capitalize">
                                {ownershipStrings[auction.property.ownershipType]}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {auction.property?.description && (
                <>
                    <h3 className="mt-5">Kohde-esittely</h3>
                    <div className="text-left" dangerouslySetInnerHTML={{ __html: auction.property.description }} />
                </>
            )}

            {auction.description && (
                <>
                    <h3 className="mt-5">Ilmoitusteksti</h3>
                    <div className="text-left" dangerouslySetInnerHTML={{ __html: auction.description }} />
                </>
            )}

            {auction.files.length > 0 && (
                <>
                    <h3 className="mt-5">Tiedostot</h3>
                    {auction.files.map((file, index) => (
                        <div key={index} className="flex items-center">
                            <DownloadOutlined className="mr-2 text-2xl" />
                            <div>
                                <Link
                                    to={URL + "/lataa/" + file.id}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline text-blue-900"
                                >
                                    {file.description}
                                </Link>
                            </div>
                        </div>
                    ))}
                </>
            )}

            {auction.links.length > 0 && (
                <>
                    <h3 className="mt-5">3D- tai videoesittelyt</h3>
                    {auction.links.map((link, index) => (
                        <div key={link.id} className="flex items-center">
                            <div className="mr-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                    />
                                </svg>
                            </div>
                            <Link to={`https://${link.url}`} target="_blank" className="underline text-blue-900">
                                {link.description ? link.description : `Esittely #${index + 1}`}
                            </Link>
                        </div>
                    ))}
                </>
            )}

            {/* {auction.mandator ? (
                <>
                    <h3 className="mt-5">Toimeksiantaja</h3>
                    <div className="grid gap-2" style={{ gridTemplateColumns: 'min-content auto' }}>
                        <div className="w-36">
                            <strong>Nimi:</strong>
                        </div>
                        <div>{auction.mandator.name}</div>
                        {auction.mandator.businessId && (
                            <>
                                <div>
                                    <strong>Y-tunnus:</strong>
                                </div>
                                <div>{auction.mandator.businessId}</div>
                            </>
                        )}
                        <div>
                            <strong>Osoite:</strong>
                        </div>
                        <div>{auction.mandator.address}</div>
                        <div>
                            <strong>Puhelin:</strong>
                        </div>
                        <div>{auction.mandator.phonenumber}</div>
                        <div>
                            <strong>Sähköposti:</strong>
                        </div>
                        <div>{auction.mandator.email}</div>
                    </div>
                </>
            ) : null} */}
        </div>
    );
};

export default AuctionDetails;
