import React, { useState, useContext, useEffect } from "react";

import ProfileEditModal from "../../components/modals/ProfileEditModal";
import PasswordChangeModal from "../../components/modals/PasswordChangeModal";

import { Button, Spin } from "antd";

import { AuctionTypeLabels } from "../../constants/AuctionTypes";
import { AuthContext } from "../../context/AuthContext";

import api from "../../services/Api";
import { Link } from "react-router-dom";

const Profile = () => {
    const { user } = useContext(AuthContext);
    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [auctionOffers, setAuctionOffers] = useState([]);
    const [auctionOffersLoading, setAuctionOffersLoading] = useState(false);
    const [approvingOffer, setApprovingOffer] = useState(0);

    const fetchAuctionOffers = async () => {
        setAuctionOffersLoading(true);

        try {
            const response = await api.getAuctionOffers();
            setAuctionOffers(response);
        } catch (error) {
            //console.error(error);
        } finally {
            setAuctionOffersLoading(false);
        }
    };

    useEffect(() => {
        fetchAuctionOffers();
    }, []);

    const showProfileEditModal = () => {
        setIsProfileEditModalOpen(true);
    };

    const closeProfileEditModal = () => {
        setIsProfileEditModalOpen(false);
    };

    const showPasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(true);
    };

    const closePasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(false);
    };

    const handleAcceptOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.acceptOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleRejectOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.rejectOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleAuthentication = () => {
        window.location.href = user.authUrl;
    };

    return (
        <>
            <ProfileEditModal user={user.uuid} open={isProfileEditModalOpen} onClose={closeProfileEditModal} />
            <PasswordChangeModal open={isPasswordChangeModalOpen} onClose={closePasswordChangeModal} />

            {user.verified === false && (
                <div className="bg-yellow-200 p-5 mb-5 flex items-center justify-between">
                    <div>
                        <div className="text-lg font-bold">Tiliäsi ei ole vielä vahvistettu</div>
                        <div className="text-base">
                            Jotta voit tehdä tarjouksia tai jättää ilmoituksia, tulee sinun tunnistautua
                            pankkitunnuksilla.
                        </div>
                    </div>
                    <Button type="primary" size="large" onClick={handleAuthentication}>
                        Tunnistaudu
                    </Button>
                </div>
            )}

            <div className="flex justify-between items-start">
                <div>
                    <h1 className="text-2xl mb-0 font-normal">{user.name}</h1>
                    <h2 className="mb-5 font-normal">{user.email}</h2>
                </div>
                <div className="space-x-2">
                    <Button type="primary" onClick={showProfileEditModal}>
                        Muokkaa profiilia
                    </Button>
                    <Button type="primary" onClick={showPasswordChangeModal}>
                        Vaihda salasana
                    </Button>
                </div>
            </div>
            <hr className="border-bottom border-gray-400" />
            <div className="mt-5">
                {auctionOffersLoading ? (
                    <Spin />
                ) : (
                    <>
                        <h2 className="text-xl font-bold">Avoimet tarjoukset</h2>
                        {auctionOffers.pendingOffers?.length > 0 ? (
                            <div className="text-lg grid grid-cols-2 gap-5">
                                {auctionOffers.pendingOffers.map((offer) => (
                                    <div key={offer.auction.uuid} className="shadow-md p-3 relative">
                                        {approvingOffer === offer.id && (
                                            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 flex items-center justify-center">
                                                <Spin />
                                            </div>
                                        )}
                                        <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                            {AuctionTypeLabels[offer.auction.type]}
                                        </div>
                                        <div className="space-y-2">
                                            <div>
                                                <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                {offer.auction.title}
                                            </div>
                                            <div>
                                                <div className="text-sm">Tarjous annettu</div>
                                                {offer.createdAt}
                                            </div>
                                            <div>
                                                <div className="text-sm">
                                                    {offer.auction.type === "auction" ? "Korkein tarjous" : "Tarjous"}
                                                </div>
                                                {offer.amount} &euro;
                                            </div>
                                        </div>
                                        <div className="flex justify-between mt-5">
                                            <Button type="primary" danger onClick={() => handleRejectOffer(offer.id)}>
                                                Hylkää
                                            </Button>
                                            <Button type="primary" onClick={() => handleAcceptOffer(offer.id)}>
                                                Hyväksy
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-xl">Ei avoimia tarjouksia</div>
                        )}

                        <h2 className="text-xl font-bold mt-5">Hyväksytyt tarjoukset</h2>

                        {auctionOffers.pendingDeals?.length > 0 ? (
                            <div className="text-lg grid grid-cols-2 gap-5">
                                {auctionOffers.pendingDeals.map((offer) => (
                                    <div key={offer.auction.uuid} className="shadow-md p-3">
                                        <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                            {AuctionTypeLabels[offer.auction.type]}
                                        </div>
                                        <div className="space-y-2">
                                            <div>
                                                <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                {offer.auction.title}
                                            </div>
                                            <div>
                                                <div className="text-sm">Tarjous annettu</div>
                                                {offer.createdAt}
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <div className="text-sm">
                                                        {offer.auction.type === "auction"
                                                            ? "Korkein tarjous"
                                                            : "Tarjous"}
                                                    </div>
                                                    {offer.amount}
                                                </div>

                                                <Link to={`/oma-sivu/sopimus/${offer.auction.uuid}`}>
                                                    <Button type="primary" className="ml-5">
                                                        Luo{" "}
                                                        {offer.auction.type === "rent"
                                                            ? "vuokrasopimus"
                                                            : "kauppakirja"}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-xl">Ei hyväksyttyjä tarjouksia</div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Profile;
