import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import api from "../../services/Api";
import { Spin } from "antd";
import ContractForm from "../../components/forms/ContractForm";

const Contract = () => {
    const [auction, setAuction] = useState(null);
    const [loading, setLoading] = useState(true);

    const { uuid } = useParams();

    const ownershipStrings = {
        "1/4": "joka neljäs viikko, katso viikkoliite",
        "1/6": "joka kuudes viikko, katso viikkoliite",
        "1/8": "joka kahdeksas viikko, katso viikkoliite",
        "1/10": "joka kymmenes viikko, katso viikkoliite",
    };

    useEffect(() => {
        setLoading(true);

        const fetchAuction = async () => {
            try {
                const response = await api.getUserAuction(uuid);
                setAuction(response);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        fetchAuction();
    }, [uuid]);

    return (
        <>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <div className="text-base mb-5">
                        <h1>Kauppakirja nro {auction.number}</h1>

                        <div className="font-bold uppercase">Kaupan kohde</div>
                        <div className="mb-5">{auction.property.title}, huoneisto {auction.roomNumber}, viikko {auction.propertyWeek}</div>

                        {/* <div className="font-bold uppercase">Kauppahinta</div>
                        <div className="mb-5">{auction.auctionType === 'rent' ? auction.startPrice : auction.acceptedBid.amount} &euro;</div> */}

                        {/* <div className="font-bold">Kohteen tiedot</div>
                        {auction.property?.address && (
                            <div>
                                <div>
                                    Osoite: {auction.property?.address}, {auction.property?.zipcode}{" "}
                                    {auction.property?.city}
                                </div>
                                {auction.roomNumber && <div>Huoneiston tunnus: {auction.roomNumber}</div>}
                                {auction.apartmentDescription && (
                                    <div>Huoneistokuvaus: {auction.apartmentDescription}</div>
                                )}
                                {auction.propertyAttributes?.area && (
                                    <div>Huoneiston koko: {auction.propertyAttributes.area} m²</div>
                                )}
                                {auction.propertyAttributes?.beds && (
                                    <div>
                                        Nukkumapaikkoja: {auction.propertyAttributes.beds}
                                        {auction.propertyAttributes.extrabeds && (
                                            <> + {auction.propertyAttributes.extrabeds}</>
                                        )}
                                    </div>
                                )}
                                <div>
                                    Omistusmuoto:{" "}
                                    {auction.property.ownershipType === "week"
                                        ? "viikko-osake"
                                        : auction.property.ownershipType + " osaomistus"}
                                </div>
                                {auction.maintenanceCharge && auction.auctionType !== "rent" && (
                                    <>
                                        <div>
                                            Viimeisin vahvistettu vastikkeen määrä: {auction.maintenanceCharge} &euro;
                                        </div>
                                        <div>
                                            Vastikkeen maksuväli:{" "}
                                            {auction.property.ownershipType === "week" ? "vuosi" : "kuukausi"}
                                        </div>
                                    </>
                                )}

                                {auction.propertyWeek && (
                                    <div className="mt-5">
                                        <div className="mb-0 font-bold">
                                            {auction.type !== "rent" ? (
                                                <>Seuraava huoneiston käyttöoikeus</>
                                            ) : (
                                                <>Huoneisto vuokrataan ajalle</>
                                            )}
                                        </div>
                                        <div>
                                            {auction.property.ownershipType === "week" ? (
                                                <>
                                                    Viikko {auction.propertyWeek} / {auction.propertyWeekDates}
                                                </>
                                            ) : (
                                                <div className="first-letter:capitalize">
                                                    {ownershipStrings[auction.property.ownershipType]}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )} */}
                    </div>

                    <ContractForm auction={auction} />
                </>
            )}
        </>
    );
};

export default Contract;
